import classNames from "classnames";
import React, { PureComponent } from "react";
import "./Spinner.scss";

interface Props {
  className?: string;
  large?: boolean;
  white?: boolean;
}

export default class Spinner extends PureComponent<Props> {
  public render(): JSX.Element {
    const { className, large, white } = this.props;
    return (
      <div
        className={classNames(
          "spinner",
          large && "spinner-large",
          white && "spinner-white",
          className,
        )}
      >
        <div className="spinner-bounce spinner-bounce1" />
        <div className="spinner-bounce spinner-bounce2" />
        <div className="spinner-bounce spinner-bounce3" />
      </div>
    );
  }
}
