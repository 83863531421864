import classNames from "classnames";
import React, { memo, ReactElement, useCallback } from "react";
import { chainFrom, range } from "transducist";
import "./PageList.scss";

interface PageListProps {
  className?: string;
  pageCount: number;
  currentPage: number;
  onPageSelected(pageNumber: number): void;
}

export const PageList = memo(function PageList({
  className,
  pageCount,
  currentPage,
  onPageSelected,
}: PageListProps): ReactElement {
  return (
    <div className={classNames("page-list-row", className)}>
      {displayButtonsBeforeCurPage(currentPage, onPageSelected)}
      {
        <PageButton
          key={currentPage}
          pageNumber={currentPage}
          isActive={true}
          onClick={onPageSelected}
        />
      }
      {displayButtonsAfterCurPage(currentPage, onPageSelected, pageCount)}
    </div>
  );
});

interface PageButtonProps {
  pageNumber: number;
  isActive: boolean;
  onClick(pageNumber: number): void;
}

const PageButton = memo(function PageButton({
  pageNumber,
  isActive,
  onClick,
}: PageButtonProps): ReactElement {
  const handleClick = useCallback(() => onClick(pageNumber), [
    pageNumber,
    onClick,
  ]);
  return (
    <div
      className={classNames("page-button", isActive && "page-button-active")}
      onClick={handleClick}
    >
      {pageNumber + 1}
    </div>
  );
});

const displayButtonsBeforeCurPage = (
  currentPage: number,
  onPageSelected: (geNumber: number) => void,
) => {
  if (currentPage <= 3) {
    return chainFrom(range(0, currentPage))
      .map((i) => (
        <PageButton
          key={i}
          pageNumber={i}
          isActive={false}
          onClick={onPageSelected}
        />
      ))
      .toArray();
  }

  return (
    <>
      <PageButton
        key={0}
        pageNumber={0}
        isActive={false}
        onClick={onPageSelected}
      />
      <div className="m-2">...</div>
      {chainFrom(range(currentPage - 2, currentPage))
        .map((i) => (
          <PageButton
            key={i}
            pageNumber={i}
            isActive={false}
            onClick={onPageSelected}
          />
        ))
        .toArray()}
    </>
  );
};

const displayButtonsAfterCurPage = (
  currentPage: number,
  onPageSelected: (geNumber: number) => void,
  pageCount: number,
) => {
  if (pageCount - 1 - currentPage <= 3) {
    return chainFrom(range(currentPage + 1, pageCount))
      .map((i) => (
        <PageButton
          key={i}
          pageNumber={i}
          isActive={false}
          onClick={onPageSelected}
        />
      ))
      .toArray();
  }
  return (
    <>
      {chainFrom(range(currentPage + 1, currentPage + 3))
        .map((i) => (
          <PageButton
            key={i}
            pageNumber={i}
            isActive={false}
            onClick={onPageSelected}
          />
        ))
        .toArray()}
      <div className="m-2">...</div>
      <PageButton
        key={pageCount - 1}
        pageNumber={pageCount - 1}
        isActive={false}
        onClick={onPageSelected}
      />
    </>
  );
};
