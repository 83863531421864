/** @jsx jsx */
import { Fragment, memo, ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jsx, BoxProps, Box, Text, Flex } from "theme-ui";
import { chainFrom, range } from "transducist";
import Web3 from "web3";
import {
  TokenSelection,
  Token,
  MAX_SELECTED_TOKENS,
  TokenAddress,
  RootState,
  fetchAddressesAction,
} from "../../redux/root";
import { getWalletWeb3 } from "../../util/web3";
import Table, { ColumnDescription } from "../common/Table";
import TokenBadge from "../common/TokenBadge";

export interface RankingSelectedTokensViewProps extends BoxProps {
  totalAllocation: number;
  tokens: Token[];
  selectedTokens: TokenSelection[];
}

const COLUMN_DESCRIPTIONS: Array<ColumnDescription<string, undefined>> = [
  {
    title: "Position",
    render: () => {
      return "#23";
    },
  },
  {
    title: "Tokens",
    render: () => {
      return "stuff";
    },
  },
  {
    title: "Balance",
    render: () => {
      return "$1,900,340.00";
    },
  },
  {
    title: "Prize",
    width: "50px",

    render: () => {
      return "$200";
    },
  },
];

const RankingSelectedTokensView = memo(function RankingSelectedTokensView({
  totalAllocation,
  tokens,
  selectedTokens,
  ...boxProps
}: RankingSelectedTokensViewProps): ReactElement {
  const dispatch = useDispatch();

  const addresses = useSelector((state: RootState) => state.addresses);
  useEffect(() => {
    dispatch(fetchAddressesAction());
  }, []);

  return (
    <Fragment>
      <Box
        {...boxProps}
        px={4}
        py={4}
        sx={{ borderRadius: "40px", backgroundColor: "#0B162F" }}
      >
        <Flex sx={{ justifyContent: "space-between" }}>
          <Text sx={{ fontSize: 2 }}>
            <span sx={{ fontWeight: "bold" }}>Your Tokens</span>{" "}
          </Text>
          {renderYourBalance()}
        </Flex>
        <Flex mt={4} sx={{ justifyContent: "space-between" }}>
          {chainFrom(range(MAX_SELECTED_TOKENS))
            .map((i) => (
              <Flex
                sx={{ flexDirection: "column", alignItems: "center" }}
                key={i}
              >
                <TokenBadge
                  token={{
                    address: "0x123" as TokenAddress,
                    name: "US Coins",
                    symbol: "USDT",
                    ethPrice: 0.0001,
                    ethMarketCap: 38982841,
                    uri: "http://google.com",
                  }}
                />
                <span sx={{ pt: 2, fontSize: "20px" }}>$100,000.00</span>
              </Flex>
            ))
            .toArray()}
        </Flex>
      </Box>
      <Table<string, undefined>
        sx={{ mx: 5, mt: 5 }}
        // className={classNames("team-table")}
        rows={["sdf", "ssdfd", "sdf"]}
        columnDescriptions={COLUMN_DESCRIPTIONS}
        theme="translucent"
        // extraProps={{
        //   now,
        //   currentUser,
        //   admins,
        //   deleteTeamMember,
        //   makeTeamMemberAdmin,
        //   removeTeamMemberAdmin,
        // }}
        // getRowKey={getUserId}
        // isLoading={users.isLoading}
        // emptyMessage="No team members found."
        // borderless={true}
      />
    </Fragment>
  );

  function renderYourBalance(): ReactElement {
    const remainingAllocation =
      totalAllocation -
      chainFrom(selectedTokens)
        .map((token) => token.allocation)
        .sum();
    return (
      <Text sx={{ fontSize: 2 }}>
        <span sx={{ fontWeight: "bold", color: "orange.4" }}>
          Your Balance:
        </span>{" "}
        <span sx={{ color: remainingAllocation < 0 ? "red.4" : "inherit" }}>
          {remainingAllocation} ETH
        </span>
      </Text>
    );
  }
});

async function getWeb3() {
  const wallet: Web3 = await getWalletWeb3();
  console.log(wallet);
  console.log(await wallet.eth.getAccounts());
}

export default RankingSelectedTokensView;
