import React, { ReactNode } from "react";

interface Props {
  readonly ms: number;
  readonly children: ReactNode;
  readonly placeholder?: ReactNode;
}

interface State {
  readonly ready: boolean;
}

export default class Delay extends React.Component<Props, State> {
  public state: State = {
    ready: false,
  };
  private timeoutID?: number;

  public componentDidMount(): void {
    this.timeoutID = window.setTimeout(() => {
      this.setState({ ready: true });
    }, this.props.ms);
  }

  public componentWillUnmount(): void {
    window.clearTimeout(this.timeoutID);
  }

  public render(): ReactNode {
    const { children, placeholder = null } = this.props;
    return this.state.ready ? children : placeholder;
  }
}
