/** @jsx jsx */
import { memo, ReactElement } from "react";
import { useSelector } from "react-redux";
import { Box, Flex, jsx } from "theme-ui";
import Layout from "../components/Layout";
import RankingSelectedTokensView from "../components/ranking/RankingSelectedTokensView";
import { titleSx } from "../components/style";
import { RootState, TOTAL_ALLOCATION } from "../redux/root";
import { emptyArray } from "../util/empty";

const RankingPage = memo(function RankingPage(): ReactElement {
  const tokens = useSelector((state: RootState) => state.tokens);
  const selectedTokens = useSelector(
    (state: RootState) => state.selectedTokens,
  );

  return (
    <Layout title="Rankings">
      <Flex mx={4}>
        <Box sx={titleSx}>Rankings</Box>
      </Flex>

      <RankingSelectedTokensView
        mt={3}
        totalAllocation={TOTAL_ALLOCATION}
        tokens={tokens.value ?? emptyArray}
        selectedTokens={selectedTokens}
      />
    </Layout>
  );
});
export default RankingPage;
