import classNames from "classnames";
import React, {
  Fragment,
  memo,
  ReactChild,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Comparator } from "../util/comparators";
import Delay from "./Delay";
import { PageList } from "./PageList";
import Spinner from "./Spinner";
import "./Table.scss";

export type TableProps<T, E = void> = E extends void
  ? CommonTableProps<T, E>
  : CommonTableProps<T, E> & { extraProps: E };

interface CommonTableProps<T, E> {
  className?: string;
  rows: T[];
  columnDescriptions: Array<ColumnDescription<T, E>>;
  isLoading?: boolean;
  loadingDelay?: number;
  emptyMessage?: string;
  initialSortColumnKey?: string | number;
  initialSortAscending?: boolean;
  sorting?: SortState;
  borderless?: boolean;
  theme?:
    | "translucent"
    | "light"
    | "usage-bar-chart"
    | "mempool"
    | "aa-webhooks";
  hasIndexColumn?: boolean;
  pageSize?: number;
  rowDropdown?(row: T): JSX.Element | undefined;
  getRowKey(row: T, i: number): string | number;
  getRowClassName?(row: T, extraProps: E): string | undefined;
  onSortingChange?(sorting: SortState): void;
}

export enum Alignment {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export type ColumnDescription<T, E = void> = TitleAndKeyDescription<T, E> & {
  align?: Alignment;
  comparator?: Comparator<ColumnProps<T, E>>;
  width?: string;
  render(row: T, extra: E): ReactChild | null | undefined;
};

export interface ColumnProps<T, E = void> {
  row: T;
  extra: E;
}

// Key is optional if the title is a plain string.
type TitleAndKeyDescription<T, E = void> =
  | { title: string; key?: string | number }
  | {
      title: ReactElement | ((rows: T[], extra: E) => string | ReactElement);
      key: string | number;
    };

export interface SortState {
  sortColumnKey: string | number;
  sortAscending: boolean;
}

const MemoizedTable: any = memo(function Table<T, E = void>(
  props: TableProps<T, E>,
): ReactElement {
  const {
    className,
    rows,
    isLoading,
    loadingDelay = 1000,
    rowDropdown,
    emptyMessage,
    initialSortColumnKey,
    initialSortAscending = true,
    sorting: sortingFromProps,
    borderless,
    getRowKey,
    getRowClassName,
    onSortingChange,
    theme = "light",
    hasIndexColumn,
    pageSize,
  } = props;

  // Handles pagination if pageSize is passed in.
  const [pageNumber, setPageNumber] = useState(0);

  const columnDescriptions: Array<ColumnDescription<T, E>> =
    props.columnDescriptions;
  const extraProps: E = (props as any).extraProps;

  const [sortingFromState, setSortingFromState] = useState<
    SortState | undefined
  >(
    initialSortColumnKey != null
      ? {
          sortColumnKey: initialSortColumnKey,
          sortAscending: initialSortAscending,
        }
      : undefined,
  );

  const sorting = sortingFromProps || sortingFromState;

  const setSorting = useCallback(
    (newSorting: SortState): void => {
      setSortingFromState(newSorting);
      if (onSortingChange) {
        onSortingChange(newSorting);
      }
      // Whenever you reset sorting, jump to first page
      setPageNumber(0);
    },
    [onSortingChange],
  );

  // Whenever you reload the rows, jump to the first page.
  useEffect(() => {
    setPageNumber(0);
  }, [rows]);

  const sortedRows = useMemo(() => {
    if (!sorting) {
      return rows;
    }
    const { sortColumnKey, sortAscending } = sorting;
    const sortColumn = columnDescriptions.find(
      (c) => getKey(c) === sortColumnKey,
    );
    const comparator = sortColumn && sortColumn.comparator;
    if (!comparator) {
      console.error(
        `Tried to sort on non-existant column key: ${sortColumnKey}`,
      );
      return rows;
    }
    const orientedComparator = sortAscending
      ? comparator
      : reverseComparator(comparator);
    return rows
      .map((row) => ({ row, extra: extraProps }))
      .sort(orientedComparator)
      .map(({ row }) => row);
  }, [rows, extraProps, columnDescriptions, sorting]);

  // Handles pagination if pageSize is passed in.
  let displayedRows = sortedRows;
  let pageCount = 0;
  if (pageSize) {
    displayedRows = sortedRows.slice(
      pageNumber * pageSize,
      (pageNumber + 1) * pageSize,
    );
    pageCount = Math.ceil(sortedRows.length / pageSize);
  }

  return (
    <>
      <div
        className={classNames(
          "table-container",
          `table-theme-${theme}`,
          borderless && "table-container-borderless",
          className,
        )}
      >
        <table className="table">
          <colgroup>
            {columnDescriptions.map((description) => (
              <col
                key={getKey(description)}
                style={
                  description.width ? { width: description.width } : undefined
                }
              />
            ))}
          </colgroup>
          <thead className="table-header">
            <tr>
              {hasIndexColumn && (
                <th className="table-header-index-cell pl-2">#</th>
              )}
              {columnDescriptions.map((description) => {
                const key = getKey(description);
                const title: ReactChild =
                  typeof description.title === "function"
                    ? description.title(rows, extraProps)
                    : description.title;
                return (
                  <ColumnHeader
                    key={key}
                    columnKey={key}
                    title={title}
                    align={description.align || Alignment.LEFT}
                    isSortable={!!description.comparator}
                    sorting={getSortingForColumn(key)}
                    setSorting={setSorting}
                  />
                );
              })}
            </tr>
          </thead>
          <tbody className="table-body">
            {!isLoading &&
              displayedRows.map((row, i) => {
                const standardRow = (
                  <tr
                    className={classNames(
                      "table-row",
                      getRowClassName && getRowClassName(row, extraProps),
                    )}
                  >
                    {hasIndexColumn && (
                      <td className="muted pl-2 table-index-cell">{i + 1}</td>
                    )}
                    {columnDescriptions.map((description) => (
                      <td
                        key={getKey(description)}
                        className={classNames("table-cell", {
                          "text-right": description.align === Alignment.RIGHT,
                        })}
                      >
                        {description.render(row, extraProps)}
                      </td>
                    ))}
                  </tr>
                );
                if (rowDropdown && rowDropdown(row)) {
                  return (
                    <Fragment key={getRowKey(row, i)}>
                      {standardRow}
                      <tr className="table-row">
                        <td
                          className="table-dropdown-row"
                          colSpan={columnDescriptions.length}
                        >
                          {rowDropdown(row)}
                        </td>
                      </tr>
                    </Fragment>
                  );
                }
                return standardRow;
              })}
          </tbody>
        </table>
        {!isLoading && displayedRows.length === 0 && (
          <div className="table-empty-message">
            {emptyMessage || "No results."}
          </div>
        )}
        {isLoading && renderLoadingRow()}
      </div>
      {pageSize && (
        <div className="d-flex justify-content-center">
          <PageList
            className="mt-3"
            pageCount={pageCount}
            currentPage={pageNumber}
            onPageSelected={setPageNumber}
          />
        </div>
      )}
    </>
  );

  function getSortingForColumn(key: string | number): ColumnHeaderSort {
    if (!sorting || sorting.sortColumnKey !== key) {
      return ColumnHeaderSort.UNSELECTED;
    }
    return sorting.sortAscending
      ? ColumnHeaderSort.ASCENDING
      : ColumnHeaderSort.DESCENDING;
  }

  function renderLoadingRow(): JSX.Element | null {
    const spinner = (
      <div className="table-loading-row">
        <Spinner />
      </div>
    );
    return rows.length === 0 ? (
      spinner
    ) : (
      <Delay ms={loadingDelay}>{spinner}</Delay>
    );
  }
});

// Jump through a small hoop to allow exported functional component to be both
// memoized and genericized.
// tslint:disable-next-line:function-name
export default function Table<T, E = void>(
  props: TableProps<T, E>,
): ReactElement {
  return <MemoizedTable {...props} />;
}

interface ColumnHeaderProps {
  columnKey: string | number;
  title: string | ReactElement;
  align: Alignment;
  isSortable: boolean;
  sorting: ColumnHeaderSort;
  setSorting(sorting: SortState): void;
}

enum ColumnHeaderSort {
  UNSELECTED = "unselected",
  ASCENDING = "ascending",
  DESCENDING = "descending",
}

const ColumnHeader = memo(function ColumnHeader({
  columnKey,
  title,
  align,
  isSortable,
  sorting,
  setSorting,
}: ColumnHeaderProps): ReactElement {
  const handleSortClick = useCallback(() => {
    if (isSortable) {
      setSorting({
        sortColumnKey: columnKey,
        sortAscending: sorting !== ColumnHeaderSort.ASCENDING,
      });
    }
  }, [isSortable, setSorting, columnKey, sorting]);

  return (
    <th
      className={classNames(
        "table-header-cell",
        align === Alignment.RIGHT && "text-right",
        isSortable && "table-header-cell-sortable",
        sorting === ColumnHeaderSort.ASCENDING && "table-header-cell-ascending",
        sorting === ColumnHeaderSort.DESCENDING &&
          "table-header-cell-descending",
      )}
      onClick={handleSortClick}
    >
      <div>{title}</div>
    </th>
  );
});

function getKey(
  description: TitleAndKeyDescription<any, any>,
): string | number {
  if (description.key == null) {
    return (description as { title: string; key?: string | number }).title;
  }
  return description.key;
}

function reverseComparator<T>(
  f: (a: T, b: T) => number,
): (a: T, b: T) => number {
  return (a, b) => -f(a, b);
}
